body.timetable section#mobile {
  pointer-events: none;
}

body.timetable section#mobile > .container {
  scale: 1.3;
  /* scale: 1.3; */
}

body.timetable section#mobile > .container > .item {
  opacity: 0;
}

body section#timetable {
  scale: 1.3;
  pointer-events: none;
}

body.timetable section#timetable {
  scale: 1;
  /* translate: 0 0; */
  pointer-events: auto;
}

section#timetable,
section#timetable > .container,
section#timetable .mainTransition {
  --_duration: 0.35s;
  transition: opacity var(--_duration) ease, scale var(--_duration) ease;
  /* transition: translate 0.25s ease-out; */
}

section#timetable .mainTransition {
  opacity: 0;
}

body.timetable section#timetable,
body.timetable section#timetable > .container,
body.timetable section#timetable .mainTransition {
  opacity: 1;
}

section#timetable {
  /* sizing */
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  grid-area: main;

  /* variables */
  --_outer_border-radius: 1rem;
  --_inner_border-radius: 0.5rem;
  --_inside_border-radius: calc(var(--_outer_border-radius) / 2);
  --_inside-inner_border-radius: calc(var(--_inner_border-radius) / 2);
}

section#timetable > .container {
  /* sizing */
  height: 100%;
  width: 100%;
  padding: 0.5rem;

  /* flex */
  display: flex;
}

section#timetable > .container > button.playing {
  /* grid */
  grid-row: 2/2;

  /* styling */
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}

/*** Unsupported ***/
section#timetable > .error {
  /* sizing */
  width: 100%;
  height: 100%;

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;

  /* text */
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

}