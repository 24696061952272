.lowerBar {
  /* position */
  position: fixed;
  inset: 0.5rem;
  top: auto;

  /* sizing */
  padding: 0.5rem;

  /* flex */
  display: grid;
  grid-template-columns: 2.5rem 1fr repeat(2, 2.5rem);
  gap: 0;

  /* styling */
  border-radius: var(--_inner_border-radius);
  backdrop-filter: brightness(0.75) blur(10px);
  background-color: transparent;
  border: none;
}

.lowerBar > .image {
  /* sizing */
  aspect-ratio: 1;

  /* flex */
  display: flex;

  /* styling */
  overflow: clip;
  border-radius: var(--_inside-inner_border-radius);
}

.lowerBar > .text {
  /* spacing */
  margin-inline: 0.75rem;

  /* flex */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;

  /* text */
  color: var(--foreground-color-100);
  text-align: left;
}

.lowerBar > .text > .title {
  /* max-width */
  max-width: min(70vw, 20ch, 90%);

  /* text */
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;

  /* overflow */
  text-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lowerBar > .text > .subTitle {
  /* max-width */
  max-width: min(70vw, 20ch, 90%);

  /* text */
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  color: var(--foreground-color-300);

  /* overflow */
  text-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lowerBar :global(#liveBtn),
.lowerBar :global(#stopBtn) {
  /* sizing */
  aspect-ratio: 1;
  padding: 0;
  margin: 0;

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;

  /* styling */
  background: transparent;
  border: none;
  color: inherit;
}
.lowerBar :global(#liveBtn) svg,
.lowerBar :global(#stopBtn) svg {
  width: 1.5rem;
  fill: currentColor;
  color: inherit;
}
