body section#mobile {
  max-width: 100vw;
  overflow: clip;
}

body.settings section#mobile {
  pointer-events: none;
}

body.settings section#mobile > .container {
  scale: 1.3;
  /* scale: 1.3; */
}

body section#settings {
  scale: 1.3;
  /* scale: 1.3; */
}

body.settings section#mobile > .container > .item {
  opacity: 0;
}

body section#settings {
  /* translate: -100vw 0; */
  /* translate: 0 100vh; */
  pointer-events: none;
}

body.settings section#settings {
  scale: 1;
  /* translate: 0 0; */
  pointer-events: auto;
}

section#settings,
section#settings > .container,
section#settings .mainTransition {
  --_duration: 0.35s;
  transition: opacity var(--_duration) ease, scale var(--_duration) ease;
  /* transition: translate 0.25s ease-out; */
}

section#settings .mainTransition {
  opacity: 0;
}

body.settings section#settings,
body.settings section#settings > .container,
body.settings section#settings .mainTransition {
  opacity: 1;
}

section#settings {
  /* sizing */
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  grid-area: main;

  /* variables */
  --_outer_border-radius: 1rem;
  --_inner_border-radius: 0.5rem;
  --_inside_border-radius: calc(var(--_outer_border-radius) / 2);
  --_inside-inner_border-radius: calc(var(--_inner_border-radius) / 2);
}

section#settings > .container {
  /* sizing */
  height: 100%;
  width: 100%;
  padding: 0.5rem;

  /* flex */
  display: flex;
}

section#settings > .container .navigationSelection {
  /* sizing */
  width: 200px;
  height: 150px;

  /* grid */
  display: grid;
  grid-template-columns: 25px 1fr 25px;
  grid-template-rows: 25px 1fr 25px;
  gap: 0.25rem;
}

section#settings > .container .navigationSelection .indicator {
  /* sizing */
  width: 100%;
  height: 100%;

  /* styling */
  border-radius: 0.5rem;
  border: 2px solid var(--foreground-color-500);
}

section#settings > .container .navigationSelection .indicator.active {
  border-color: var(--form-color-valid);
}

section#settings > .container .navigationSelection .center {
  /* sizing */
  width: 100%;
  height: 100%;
  padding: 0.25rem;

  /* grid */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.5rem;

  /* styling */
  border-radius: 0.5rem;
  border: 2px solid currentColor;
}

section#settings > .container .navigationSelection .center button {
  /* styling */
  border: 2px solid var(--foreground-color-500);
  background-color: transparent;
  border-radius: 0.25rem;
}

section#settings > .container .navigationSelection .center button:hover {
  border-color: var(--selector-color);
}