body section#mobile {
  max-width: 100vw;
  overflow: clip;
}

body.artView section#mobile {
  pointer-events: none;
}

body.artView section#mobile > .container {
  scale: 1.3;
  /* scale: 1.3; */
}

body section#artView {
  scale: 1.3;
  /* scale: 1.3; */
}

body.artView section#mobile > .container > .item {
  opacity: 0;
  /* translate: 100vw 0; */
  /* translate: 0 -100vh; */
  pointer-events: none;
}

body section#artView {
  /* translate: -100vw 0; */
  /* translate: 0 100vh; */
  pointer-events: none;

  --_outer_border-radius: 1rem;
  --_inner_border-radius: 0.5rem;
  --_inside_border-radius: calc(var(--_outer_border-radius) / 2);
  --_inside-inner_border-radius: calc(var(--_inner_border-radius) / 2);
}

body.artView section#artView {
  opacity: 1;
  scale: 1;
  /* translate: 0 0; */
  pointer-events: auto;
}

section#artView,
section#artView > .container,
section#artView > .container .item,
section#mobile > .container,
section#mobile > .container .item {
  --_duration: 0.35s;
  transition: opacity var(--_duration) ease, scale var(--_duration) ease;
  /* transition: translate 0.25s ease-out; */
}

section#artView .item {
  opacity: 0;
}

body.artView section#artView,
body.artView section#artView > .container,
body.artView section#artView .item {
  opacity: 1;
}

section#artView {
  /* position */
  /* grid-area: main; */
  grid-area: main;
}

section#artView > .container {
  /* sizing */
  height: 100%;
  grid-area: main;
  padding: 0.5rem;

  /* flex */
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

section#artView > .container .image {
  /* sizing */
  aspect-ratio: 1;
  width: 100%;

  /* flex */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* styling */
  overflow: clip;
  border-radius: var(--_outer_border-radius);
  border-top-right-radius: var(--_inner_border-radius);
  border-bottom-right-radius: var(--_inner_border-radius);
}

section#artView > .container > .image {
  width: unset;
  max-width: 50vw;
}

section#artView > .container .image button {
  /* sizing */
  padding: 0;
  margin: 0;
  aspect-ratio: 1/1 !important;
  width: 100%;

  /* flex */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* styling */
  border: none;
  border-radius: 0;
  background: transparent;
  outline: none !important;
  backdrop-filter: none !important;
}

section#artView > .container .image img {
  /* position */
  position: absolute;

  /* sizing */
  width: 100%;
  height: 100%;

  /* styling */
  object-fit: cover;
}

section#artView > .container .column {
  /* position */
  position: relative;

  /* sizing */
  width: 100%;
  height: 100%;

  /* grid */
  display: grid;
  grid-template-columns: [content-start controlsLive-start controlsPaused-start] 1fr [ controlsLive-end controlsStop-start] 1fr [content-end controlsStop-end controlsPaused-end];
  grid-template-rows: [content-start] 1fr [content-end controlsLive-start controlsStop-start controlsPaused-start] 1fr [controls-end controlsLive-end controlsStop-end controlsPaused-end];
  gap: 0.25rem;
}

section#artView > .container .content {
  grid-area: content;

  /* sizing */
  padding: var(--_inside_border-radius);

  /* flex */
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;

  /* styling */
  overflow: clip;
  border-radius: var(--_inner_border-radius);
  border-top-right-radius: var(--_outer_border-radius);
  backdrop-filter: blur(10px) brightness(0.8);
}

section#artView > .container .dj {
  /* position */
  position: absolute;
  inset: 0;
  bottom: auto;
  z-index: 1;

  /* sizing */
  --_margin: var(--_inside_border-radius);
  height: fit-content;
  padding: 0.5rem;
  margin: var(--_margin);

  /* flex */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  /* text */
  text-align: left;

  /* styling */
  border-radius: var(--_inside_border-radius);
  background: transparent;
  border: none;
  backdrop-filter: brightness(1.25) blur(10px);
}

body.oled section#artView > .container .dj {
  /* styling */
  background: initial;
  outline: 3px solid var(--background-color-100);
  outline-offset: -3px;
  --_margin: 0px;
}

section#artView > .container .dj img {
  /* sizing */
  --_size: 50px;
  height: var(--_size);
  width: var(--_size);
  flex-shrink: 0;

  /* styling */
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

section#artView > .container .dj .about {
  /* sizing */
  height: 100%;

  /* flex */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section#artView > .container .dj .about .title {
  /* text */
  font-size: 22px;
  font-weight: 700;
  line-height: 1;

  /* styling */
  color: var(--foreground-color-100);
}

section#artView > .container .dj .about .subTitle {
  /* text */
  font-size: 12px;
  font-weight: 500;
  line-height: 1;

  /* styling */
  color: var(--foreground-color-100);
}

section#artView > .container .dj .about .subTitle * {
  padding: 0;
  margin: 0;
}

section#artView > .container span.title {
  /* text */
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;

  /* styling */
  color: var(--dnu-light-color-100);
}

section#artView > .container span.subTitle {
  /* text */
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1;

  /* styling */
  color: var(--dnu-light-color-100);
}

section#artView > .container button {
  /* sizing */

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* styling */
  /* background: var(--background-color-200); */
  background: transparent;
  border: none;
  color: var(--dnu-light-color-200);
  backdrop-filter: brightness(1.25) blur(10px);
  border-radius: var(--_inner_border-radius);
}

section#artView > .container button:hover,
section#artView > .container button:focus-visible {
  /* styling */
  outline: 2px solid;
  outline-offset: -2px;
  outline-color: currentColor !important;
}

section#artView > .container button:focus-visible {
  /* styling */
  outline-color: var(--selector-color) !important;
}

section#artView > .container button svg {
  /* sizing */
  --_size: 15svmin;
  width: var(--_size);
  height: var(--_size);

  /* styling */
  fill: currentColor;
  color: inherit;
}

section#artView > .container button.paused {
  grid-area: controlsPaused;

  border-bottom-right-radius: var(--_outer_border-radius);
}

section#artView > .container button.play {
  grid-area: controlsLive;
}

section#artView > .container button.stop {
  grid-area: controlsStop;

  border-bottom-right-radius: var(--_outer_border-radius);
}

/** Player Background Animated **/
@media not (prefers-reduced-motion) {
  /* styles to apply if a user's device settings are set to reduced motion */
  section#mobile > .background.animated {
    /* position */
    inset: 0 !important;

    /* sizing */
    height: 150svh;
    width: 150svw;

    /* animation */
    animation: backgroundAnimated 90s forwards infinite linear;

    /* styling */
    object-fit: cover;
    filter: blur(50px) brightness(65%);
  }
}

@media only screen and (max-width: 480px) {
  @-moz-document url-prefix() {
    /* Only applies to firefox mobile */
    section#mobile > .background.animated {
      /* sizing */
      width: calc(100% + var(--_pad));
      height: calc(100% + var(--_pad));

      /* animation */
      animation: none;

      /* styling */
      filter: blur(50px) brightness(75%);
    }
  }
}

@keyframes backgroundAnimated {
  0% {
    translate: 0svw 0svh;
  }

  25% {
    translate: -50svw 0svh;
  }

  50% {
    translate: -50svw -50svh;
  }

  75% {
    translate: 0svw -50svh;
  }
}

@media (orientation: portrait) {
  section#artView > .container {
    flex-direction: column;
  }

  section#artView > .container > .image {
    max-height: 47vh;
    max-width: unset;
  }

  section#artView > .container .image button {
    width: unset;
    height: 100%;
  }
}
