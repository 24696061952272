body.switcher section#mobile {
  pointer-events: none;
}

body.switcher section#mobile > .container {
  scale: 1.3;
  /* scale: 1.3; */
}

body.switcher section#mobile > .container > .item {
  opacity: 0;
}

body section#switcher {
  scale: 1.3;
  pointer-events: none;
}

body.switcher section#switcher {
  scale: 1;
  /* translate: 0 0; */
  pointer-events: auto;
}

section#switcher,
section#switcher > .container,
section#switcher .mainTransition {
  --_duration: 0.35s;
  transition: opacity var(--_duration) ease, scale var(--_duration) ease;
  /* transition: translate 0.25s ease-out; */
}

section#switcher .mainTransition {
  opacity: 0;
}

body.switcher section#switcher,
body.switcher section#switcher > .container,
body.switcher section#switcher .mainTransition {
  opacity: 1;
}

section#switcher {
  /* sizing */
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  grid-area: main;

  /* styling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  --_outer_border-radius: 1rem;
  --_inner_border-radius: 0.5rem;
  --_inside_border-radius: calc(var(--_outer_border-radius) / 2);
  --_inside-inner_border-radius: calc(var(--_inner_border-radius) / 2);
}

section#switcher > div.container {
  /* sizing */
  height: 100%;
  padding: var(--_inside_border-radius);
  /* overflow-x: scroll; */

  /* flex */
  display: flex;
}

body.oled section#switcher > div.container {
  /* styling */
  background: black;
  outline: 3px solid var(--background-color-100);
  outline-offset: -3px;
}

section#switcher > div.container > ul {
  /* spacing */
  width: 100%;
  min-height: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;

  /* flex */
  display: flex;
  flex-direction: column;
  gap: 10px;

  /* styling */
  list-style: none;
}

section#switcher > div.container > ul > li {
  /* sizing */
  width: 100%;
  height: 25dvh;

  /* styling */
  border-radius: 0.5rem;
  backdrop-filter: brightness(0.75) blur(10px);
  background-color: transparent;
  border: none;
  overflow: clip;
}

body.oled section#switcher > div.container > ul > li {
  /* styling */
  background: initial;
  outline: 3px solid var(--background-color-100);
  outline-offset: -3px;
}

section#switcher > div.container > ul > li > a,
section#switcher > div.container > ul > li > button {
  /* position */
  position: relative;

  /* sizing */
  width: 100%;
  height: 100%;

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;

  /* text */
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;

  /* styling */
  border: none;
  color: var(--foreground-color-100);
  background-color: transparent;
}
section#switcher > div.container > ul > li:hover,
section#switcher > div.container > ul > li:focus-within {
  /* styling */
  outline: 2px solid;
  outline-offset: -2px;
  outline-color: currentColor !important;
}
section#switcher > div.container > ul > li:focus-within {
  /* styling */
  outline-color: var(--selector-color) !important;
}

section#switcher > div.container > ul > li:active,
section#switcher > div.container > ul > li:active {
  /* styling */
  background: var(--background-color-300);
}

section#switcher > div.container > ul > li .indicator {
  /* position */
  position: absolute;
  inset: 0;
  bottom: auto;
  z-index: 0;

  /* sizing */
  padding: 0.25rem 1rem;

  /* text */
  font-size: 0.8rem;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;

  /* styling */
  /* border-radius: 5px; */
  color: white;
  background-color: red;
}

section#switcher > div.container > ul > li .indicator.seasonal {
  color: white;
  background-color: rgb(3, 147, 39);
}

section#switcher > div.container > ul > li .indicator + .indicator {
  top: 1.3rem;
}
