body section#mobile {
  max-width: 100vw;
  overflow: clip;
}

body.clockView section#mobile {
  pointer-events: none;
}

body.clockView section#mobile > .container {
  scale: 1.3;
  /* scale: 1.3; */
}

body section#clockView {
  scale: 1.3;
  /* scale: 1.3; */
}

body.clockView section#mobile > .container > .item {
  opacity: 0;
  /* translate: 100vw 0; */
  /* translate: 0 -100vh; */
  pointer-events: none;
}

body section#clockView {
  opacity: 0;
  /* translate: -100vw 0; */
  /* translate: 0 100vh; */
  pointer-events: none;
}

body.clockView section#clockView {
  opacity: 1;
  scale: 1;
  /* translate: 0 0; */
  pointer-events: auto;
}

section#clockView,
section#mobile > .container,
section#mobile > .container > .item {
  --_duration: 0.35s;
  transition: opacity var(--_duration) ease, scale var(--_duration) ease;
  /* transition: translate 0.25s ease-out; */
}

section#clockView {
  /* position */
  /* grid-area: main; */
  grid-area: main;
}

section#clockView > .container {
  /* position */
  position: relative;

  /* sizing */
  height: 100%;
  grid-area: main;
  padding: 1.5rem;
  --_image-size: clamp(5rem, 30vmin, 14rem);

  /* flex */
  display: flex;
  gap: 1rem;
}

section#clockView > .container > .clock {
  /* sizing */
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  /* flex */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: min(2rem, 8vw);

  /* styling */
  overflow: clip;
}

section#clockView > .container > .clock .time {
  /* text */
  font-size: min(30vw, 70vh);
  line-height: 0;

  /* text */
  font-family: "Modak";
}

section#clockView > .container > .clock .divider {
  /* text */
  font-size: min(18vw, 50vh);
  line-height: 0;

  /* text */
  font-family: "Modak";
  animation: blink 2s infinite;
}

@keyframes blink {
  0%, 45%, 100% {
    opacity: 1;
  }

  55%, 90% {
    opacity: 0;
  }
}

section#clockView > .container > .playing {
  /* position */
  position: absolute;
  inset: 1.5rem;
  top: auto;

  /* grid */
  display: grid;
  grid-template-columns: var(--_image-size) 1fr;
  gap: 1rem;
}

section#clockView > .container > .playing > .content {
  /* flex */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
}

section#clockView > .container .fix {
  /* sizing */
  width: 100%;

  /* flex */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

section#clockView > .container .fix button {
  /* sizing */
  padding: 0;
  margin: 0;
  aspect-ratio: 1/1 !important;
  width: min(100%, 80vmin);
  max-width: 60vh;

  /* flex */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* styling */
  border: none;
  border-radius: 0;
  background: transparent;
}

section#clockView > .container .image {
  /* sizing */
  width: 100%;
  height: 100%;

  /* flex */
  display: flex;
}

section#clockView > .container .image img {
  /* position */
  position: absolute;

  /* sizing */
  width: 100%;
  height: 100%;

  /* styling */
  object-fit: cover;
  border-radius: 1rem;
  overflow: clip;
}

section#clockView > .container > .content {
  /* flex */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

section#clockView > .container .text {
  /* flex */
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;

  /* styling */
  text-align: center;
}

section#clockView > .container .text span.title {
  /* text */
  font-size: clamp(1.5rem, 4vmin, 2.5rem);
  font-weight: 700;
  line-height: 1;
  text-align: start;

  /* styling */
  color: var(--dnu-light-color-100);
}

section#clockView > .container .text span.subTitle {
  /* text */
  font-size: clamp(1rem, 4vmin, 1.5rem);
  font-weight: 500;
  line-height: 1;
  text-align: start;

  /* styling */
  color: var(--dnu-light-color-100);
}

section#clockView > .container .controls {
  /* flex */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

section#clockView > .container .controls button {
  /* sizing */
  width: clamp(2rem, 5vmin, 3.5rem);
  aspect-ratio: 1;

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;

  /* styling */
  border: none;
  background-color: transparent;
  color: inherit;
}

section#clockView > .container .controls button svg {
  fill: currentColor;
  color: inherit;
}

/** Player Background Animated **/
@media not (prefers-reduced-motion) {
  /* styles to apply if a user's device settings are set to reduced motion */
  section#mobile > .background.animated {
    /* position */
    inset: 0 !important;

    /* sizing */
    height: 150svh;
    width: 150svw;

    /* animation */
    animation: backgroundAnimated 90s forwards infinite linear;

    /* styling */
    object-fit: cover;
    filter: blur(50px) brightness(65%);
  }
}

@media only screen and (max-width: 480px) {
  @-moz-document url-prefix() {
    /* Only applies to firefox mobile */
    section#mobile > .background.animated {
      /* sizing */
      width: calc(100% + var(--_pad));
      height: calc(100% + var(--_pad));

      /* animation */
      animation: none;

      /* styling */
      filter: blur(50px) brightness(75%);
    }
  }
}

@keyframes backgroundAnimated {
  0% {
    translate: 0svw 0svh;
  }

  25% {
    translate: -50svw 0svh;
  }

  50% {
    translate: -50svw -50svh;
  }

  75% {
    translate: 0svw -50svh;
  }
}
