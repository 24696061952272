body section#mobile {
  max-width: 100vw;
  overflow: clip;
}

body.history section#mobile {
  pointer-events: none;
}

body.history section#mobile > .container {
  scale: 1.3;
  /* scale: 1.3; */
}

body section#history {
  scale: 1.3;
  /* scale: 1.3; */
}

body.history section#mobile > .container > .item {
  opacity: 0;
}

body section#history {
  /* translate: -100vw 0; */
  /* translate: 0 100vh; */
  pointer-events: none;
}

body.history section#history {
  scale: 1;
  /* translate: 0 0; */
  pointer-events: auto;
}

section#history,
section#history > .container,
section#history .mainTransition {
  --_duration: 0.35s;
  transition: opacity var(--_duration) ease, scale var(--_duration) ease;
  /* transition: translate 0.25s ease-out; */
}

section#history .mainTransition {
  opacity: 0;
}

body.history section#history,
body.history section#history > .container,
body.history section#history .mainTransition {
  opacity: 1;
}

section#history {
  /* sizing */
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  grid-area: main;

  /* variables */
  --_outer_border-radius: 1rem;
  --_inner_border-radius: 0.5rem;
  --_inside_border-radius: calc(var(--_outer_border-radius) / 2);
  --_inside-inner_border-radius: calc(var(--_inner_border-radius) / 2);
}

section#history > .container {
  /* sizing */
  height: 100%;
  width: 100%;
  padding: 0.5rem;

  /* flex */
  display: flex;
}

/*** Unsupported ***/
section#history > .error {
  /* sizing */
  width: 100%;
  height: 100%;

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;

  /* text */
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

}