*,
*::after,
*::before {
  /* sizing */
  box-sizing: border-box;

  /* font */
  font-family: "Montserrat", sans-serif;
}

html {
  /* prevents pull down refresh  */
  overscroll-behavior: contain;
  overflow: hidden;
}

body {
  /* sizing */
  width: 100%;
  min-height: 100vh;
  margin: 0;

  /* background */
  background-color: var(--background-color-100);
  color: var(--foreground-color-100);

  /* transition */
  transition: var(--transition-background_color-600),
    var(--transition-color-600);
}

body.oled {
  background-color: black;
  color: white;
}

body.noscroll {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

#root {
  /* sizing */
  height: 100dvh;
  width: 100%;
  min-width: 100%;

  /* grid */
  display: grid;
  grid-template-rows: min-content [main-start] 1fr [main-end nav-start] min-content [nav-end];
  grid-template-columns: [main-start] 1fr [main-end];

  /* styling */
  overflow: clip;
}

#root > main {
  min-height: 85vh;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.material-symbols-outlined.active {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}

#root:has(> .SettingsIsTopLeft) {
  /* grid */
  grid-template-columns: [main-start nav-start] 1fr [main-end nav-end] !important;
  grid-template-rows: min-content [nav-start] min-content [nav-end main-start] 1fr [main-end] !important;
}

#root:has(> .SettingsIsTopRight) {
  /* grid */
  grid-template-columns: [main-start nav-start] 1fr [main-end nav-end] !important;
  grid-template-rows: min-content [nav-start] min-content [nav-end main-start] 1fr [main-end] !important;
}

@media (orientation: landscape) {
  #root {
    /* grid */
    grid-template-columns: [main-start] 1fr [main-end nav-start] min-content [nav-end] !important;
  }

  #root:has(> .SettingsIsDefaultLeft) {
    /* grid */
    grid-template-columns: [nav-start] min-content [nav-end main-start] 1fr [main-end] !important;
  }

  #root:has(> .SettingsIsTopLeft) {
    /* grid */
    grid-template-columns: [nav-start] min-content [nav-end main-start] 1fr [main-end] !important;
    grid-template-rows: min-content [main-start nav-start] 1fr [main-end nav-end] !important;
  }

  #root:has(> .SettingsIsTopRight) {
    /* grid */
    grid-template-columns: [main-start] 1fr [main-end nav-start] min-content [nav-end] !important;
    grid-template-rows: min-content [main-start nav-start] 1fr [main-end nav-end] !important;
  }
}

#root:has(> .SettingsIsRight) {
  /* grid */
  grid-template-columns: [main-start] 1fr [main-end nav-start] min-content [nav-end] !important;
}

#root:has(> .SettingsIsLeft) {
  /* grid */
  grid-template-columns: [nav-start] min-content [nav-end main-start] 1fr [main-end] !important;
  grid-template-rows: min-content [main-start nav-start] 1fr [main-end nav-end] !important;
}

#root:has(> .SettingsIsAbove) {
  /* grid */
  grid-template-columns: [main-start nav-start] 1fr [main-end nav-end] !important;
  grid-template-rows: min-content [nav-start] min-content [nav-end main-start] 1fr [main-end] !important;
}

#root:has(> .SettingsIsBelow) {
  /* grid */
  grid-template-columns: [main-start nav-start] 1fr [main-end nav-end] !important;
  grid-template-rows: min-content [main-start] 1fr [main-end nav-start] min-content [nav-end] !important;
}
