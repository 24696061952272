section#mobile {
  /* sizing */
  height: 100%;
  grid-area: main;

  /* flex */
  display: flex;
  flex-direction: column;

  --_outer_border-radius: 1rem;
  --_inner_border-radius: 0.5rem;
  --_inside_border-radius: calc(var(--_outer_border-radius) / 2);
  --_inside-inner_border-radius: calc(var(--_inner_border-radius) / 2);
}

section#mobile * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

section#mobile > .banner {
  /* sizing */
  padding: 0.5rem 1rem;

/* styling */
  background: red;
  color: white;

  /* text */
  font-size: 0.8rem;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  text-decoration: none;
}

section#mobile > .background {
  --_pad: 15rem;
  /* position */
  position: fixed !important;
  inset: calc(-1 * (var(--_pad) / 2));
  z-index: -1;

  /* sizing */
  width: calc(100% + var(--_pad));
  height: calc(100% + var(--_pad));
  max-width: unset !important;
  max-height: unset !important;

  /* styling */
  filter: blur(50px) brightness(0.5);
  object-fit: cover;
}

body.oled section#mobile > .background {
  opacity: 0;
}

section#mobile > .container {
  /* sizing */
  width: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0.5rem;

  /* grid */
  display: grid;
  gap: 0.25rem;
}

section#mobile > .container > .item {
  /* styling */
  border-radius: var(--_outer_border-radius);
  overflow: hidden;
}

section#mobile > .container > .item#live {
  /* position */
  position: relative;

  /* grid */
  grid-column: 1/3;
  grid-row: 1/3;

  /* flex */
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
}

section#mobile > .container > .item#live > .backdrop {
  /* position */
  position: absolute;
  inset: 0;
  z-index: -2;

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;

  /* styling */
  background-color: var(--background-color-200);
}

section#mobile > .container > .item#live > .backdrop > svg {
  /* sizing */
  --_size: 25dvh;
  width: var(--_size);
  height: var(--_size);

  /* styling */
  fill: currentColor;
  color: var(--background-color-500);
}

body.oled section#mobile > .container > .item#live > .backdrop > svg {
  opacity: 0.5;
}

body.oled section#mobile > .container > .item#live > .backdrop {
  /* styling */
  background: initial;
}

body.oled section#mobile > .container > .item#live:not(:has(img)) {
  /* styling */
  outline: 3px solid var(--background-color-100);
  outline-offset: -3px;
}

section#mobile > .container > .item#live .background {
  /* position */
  position: absolute !important;
  inset: 0;
  z-index: -1;

  /* sizing */
  width: 100%;
  height: 100%;

  /* image */
  filter: blur(7.5px) brightness(0.65);
  scale: 1.05;
  /* filter: blur(5vw) brightness(0.65); */
}

body.oled section#mobile > .container > .item#live .background {
  /* styling */
  opacity: 0;
}

section#mobile > .container > .item#live .background > img {
  /* image */
  object-fit: cover;
}

section#mobile > .container > .item#live .ident {
  /* position */
  position: absolute;
  inset: 0;
  z-index: -1;

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;

  /* text */
  text-align: center;
  font-size: 50px;
  font-weight: 800;
  line-height: 1;

  /* styling */
  color: var(--foreground-color-200);
}

section#mobile > .container > .item#live .info {
  /* position */
  position: relative;
  z-index: 1;

  /* sizing */
  width: 100%;
  height: 100%;
  padding: var(--_inside_border-radius);
  flex-grow: 1;

  /* flex */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.5rem;
}

body.oled section#mobile > .container > .item#live .info {
  padding: 0;
}

section#mobile > .container > .item#live .info button {
  /* sizing */
  padding: 0;
  margin: 0;
  flex-shrink: 0;

  /* styling */
  border: none;
  border-radius: 0;
  background: transparent;
}

section#mobile > .container > .item#live .info .fix {
  display: flex;
}

section#mobile > .container > .item#live .info img {
  /* sizing */
  --_size: 100px;
  height: var(--_size);
  width: var(--_size);
  flex-shrink: 0;

  /* styling */
  border-radius: var(--_inside-inner_border-radius);
  overflow: hidden;
}

section#mobile > .container > .item#live .info .text {
  /* flex */
  display: flex;
  flex-direction: column-reverse;
}

section#mobile > .container > .item#live .info span.title {
  /* text */
  font-size: 26px;
  font-weight: 700;
  line-height: 1;

  /* styling */
  color: var(--dnu-light-color-100);
}
section#mobile > .container > .item#live .info span.subTitle {
  /* text */
  font-size: 14px;
  font-weight: 500;
  line-height: 1;

  /* styling */
  color: var(--dnu-light-color-100);
}

section#mobile > .container > .item#live .dj {
  /* position */
  position: absolute;
  inset: 0;
  bottom: auto;
  z-index: 1;

  /* sizing */
  --_margin: var(--_inside_border-radius);
  height: fit-content;
  padding: 0.5rem;
  margin: var(--_margin);

  /* flex */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  /* text */
  text-align: left;

  /* styling */
  border-radius: var(--_inside_border-radius);
  background: transparent;
  border: none;
  backdrop-filter: brightness(1.25) blur(10px);
}

body.oled section#mobile > .container > .item#live .dj {
  /* styling */
  background: initial;
  outline: 3px solid var(--background-color-100);
  outline-offset: -3px;
  --_margin: 0px;
}

section#mobile > .container > .item#live .dj img {
  /* sizing */
  --_size: 50px;
  height: var(--_size);
  width: var(--_size);
  flex-shrink: 0;

  /* styling */
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

section#mobile > .container > .item#live .dj .about {
  /* sizing */
  height: 100%;

  /* flex */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section#mobile > .container > .item#live .dj .about .title {
  /* text */
  font-size: 22px;
  font-weight: 700;
  line-height: 1;

  /* styling */
  color: var(--foreground-color-100);
}

section#mobile > .container > .item#live .dj .about .subTitle {
  /* text */
  font-size: 12px;
  font-weight: 500;
  line-height: 1;

  /* styling */
  color: var(--foreground-color-100);
}

section#mobile > .container > .item#live .dj .about .subTitle * {
  padding: 0;
  margin: 0;
}

section#mobile > .container > .item#live .dj[data-count="0"],
section#mobile > .container > .item#live .dj[data-count="2"] {
  animation: slide-out 0.5s linear forwards;
}

section#mobile > .container > .item#live .dj[data-count="1"],
section#mobile > .container > .item#live .dj[data-count="3"] {
  animation: slide-in 0.5s linear forwards;
}

@keyframes slide-in {
  from {
    translate: calc(100% + var(--_margin));
  }

  to {
    translate: 0;
  }
}

@keyframes slide-out {
  from {
    translate: 0;
  }

  to {
    translate: calc(-100% - var(--_margin));
  }
}

section#mobile > .container > button.item {
  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* styling */
  /* background: var(--background-color-200); */
  background: transparent;
  border: none;
  color: var(--dnu-light-color-200);
  backdrop-filter: brightness(1.25) blur(10px);
}

section#mobile > .container > button.item:hover,
section#mobile > .container > button.item:focus-visible {
  /* styling */
  outline: 2px solid;
  outline-offset: -2px;
  outline-color: currentColor !important;
}

section#mobile > .container > button.item:focus-visible {
  /* styling */
  outline-color: var(--selector-color) !important;
}

section#mobile > .container > button.item:active {
  /* styling */
  /* background: var(--background-color-300); */
  backdrop-filter: brightness(0.75) blur(10px);
}

body.oled section#mobile > .container > button.item {
  /* styling */
  background: initial;
  outline: 3px solid var(--background-color-100);
  outline-offset: -3px;
}

section#mobile > .container > button.item.paused,
section#mobile > .container > button.item#station {
  /* grid */
  grid-column: span 2;

  /* flex */
  flex-direction: row;
  gap: 20px;
}

section#mobile > .container > button.item#station > .info {
  /* flex */
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

section#mobile > .container > button.item#station > .info span.title {
  /* text */
  font-size: 22px;
  font-weight: 700;
  line-height: 1;

  /* styling */
  color: var(--dnu-light-color-100);
}

section#mobile > .container > button.item#station > .info span.subTitle {
  /* text */
  font-size: 12px;
  font-weight: 500;
  line-height: 1;

  /* styling */
  color: var(--dnu-light-color-100);
}

section#mobile > .container > button.item > svg {
  /* sizing */
  --_size: 15svmin;
  width: var(--_size);
  height: var(--_size);

  /* styling */
  color: inherit;
  fill: currentColor;
}

@media (orientation: landscape) {
  section#mobile > .container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  section#mobile > .container > .item#liveBtn.play {
    border-radius: var(--_inner_border-radius);
  }

  section#mobile > .container > .item#live {
    border-radius: var(--_outer_border-radius);
    border-top-right-radius: var(--_inner_border-radius);
    border-bottom-right-radius: var(--_inner_border-radius);
  }

  section#mobile > .container > .item#stopBtn,
  section#mobile > .container > .item#liveBtn.paused,
  section#mobile > .container > .item#station {
    border-radius: var(--_outer_border-radius);
    border-bottom-left-radius: var(--_inner_border-radius);
    border-top-left-radius: var(--_inner_border-radius);
  }

  section#mobile > .container > .item#station {
    border-top-right-radius: var(--_inner_border-radius);
  }

  section#mobile > .container > .item#stopBtn,
  section#mobile > .container > .item#liveBtn.paused {
    border-bottom-right-radius: var(--_inner_border-radius);
  }

  section#mobile > .container > .item#live .info img {
    border-radius: var(--_inside_border-radius);
  }
}

@media (orientation: portrait) {
  section#mobile > .container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  section#mobile > .container > .item {
    border-radius: var(--_inner_border-radius);
  }

  section#mobile > .container > .item#live {
    border-radius: var(--_outer_border-radius);
    border-bottom-left-radius: var(--_inner_border-radius);
    border-bottom-right-radius: var(--_inner_border-radius);
  }

  section#mobile > .container > .item#station {
    border-radius: var(--_outer_border-radius);
    border-top-left-radius: var(--_inner_border-radius);
    border-top-right-radius: var(--_inner_border-radius);
  }
}
